import { observer } from 'mobx-react-lite';
import { ReactNodeLike } from 'prop-types';
import React, { useEffect } from 'react';
import { useStore } from '../stores';

interface Props {
    children: ReactNodeLike
}

const HailCXHubWrapper = ({ children }: Props) => {
	const { customerStore } = useStore();
    
    useEffect(() => {
		if (!customerStore.bookingHubConnection) {
            customerStore.createBookingHubConnection();
        }
	}, [])

	return (
		<>
            { children }
		</>
	);
}

export default observer(HailCXHubWrapper);