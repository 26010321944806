import React, { useEffect } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXNotificationFooter from "../../components/layout/footer/hailcx-notification-footer"
import { EuiImage, EuiSpacer, EuiText } from "@elastic/eui"
import AssessmentImage from "../../images/assessment.png"
import { navigate } from "gatsby"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import { ProcessStates, RepairTypes } from "../../entities/constants"
import agent from "../../api/agent"
import HailCXHubwrapper from "../../components/hailcx-hubwrapper"

const AssessmentPage = () => {
  const { customerStore } = useStore()
  const { customer, setCustomer } = customerStore

  const data = {
    title: "Assessment",
    notificationTitle: "PLEASE WAIT...",
    notificationContent:
      "Once your vehicle has been assessed, you'll be able to book your repair time.",
  }

  const endpointUrl =
    process.env.GATSBY_API_BASE_URL +
    "/booking-hub?quoteNumber=" +
    customer?.halQuoteNumber

  useEffect(() => {
    customerStore.saveProcessState(ProcessStates[0].Id, ProcessStates[0].Name)

    customerStore.createBookingHubConnection()
    
    if (customer && customer.repairTypeQuoted) {    
      navigate("/book/ready-to-book")
    }
  }, [])

  return (
    <HailCXHubwrapper>
      <HailCXLayout
        header={
          <HailCXRightImageHeader
            title={data.title}
            progressValue={20}
            vehicleInfo={customerStore.getVehicleInfo()}
          />
        }
        footer={
          <HailCXNotificationFooter
            callOutTitle={data.notificationTitle}
            callOutContent={data.notificationContent}
          />
        }
      >
        <EuiText
          grow={false}
          className="eui-textLeft"
          style={{ marginRight: "auto", marginLeft: "auto" }}
        >
          <h1>Inspecting vehicle...</h1>
          <p>We're performing an initial assessment on your vehicle.</p>
        </EuiText>
        <EuiSpacer />
        <EuiImage src={AssessmentImage} alt={data.title} size="1" />
      </HailCXLayout>
    </HailCXHubwrapper>
  )
}

export default observer(AssessmentPage)
