import React from "react"
import { EuiPageHeader, EuiImage, EuiProgress } from "@elastic/eui"

import "@elastic/eui/dist/eui_theme_amsterdam_light.css"
import "../../../styles/hailcx-headers.css"
import hail_logo from "../../../images/hail-logo.png"

interface Props {
  title: string
  progressValue: number
  vehicleInfo?: string
}

const HailCXRightImageHeader = ({
  title,
  progressValue,
  vehicleInfo,
}: Props) => {
  return (
    <EuiPageHeader
      paddingSize="l"
      restrictWidth
      bottomBorder={true}
      pageTitle={title}
      description={
        vehicleInfo == "undefined undefined undefined" ? "" : vehicleInfo
      }
      responsive={false}
      rightSideItems={[
        <EuiImage src={hail_logo} alt="Hail.com" float="right" />,
      ]}
    >
      {progressValue > 0 && (
        <EuiProgress
          valueText={false}
          value={progressValue}
          max={100}
          color={"success"}
          size="m"
        ></EuiProgress>
      )}
    </EuiPageHeader>
  )
}

export default HailCXRightImageHeader
