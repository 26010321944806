import React from 'react';
import { EuiBottomBar, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiPageContentBody } from '@elastic/eui';
import '../../../styles/hailcx-buttons.css';
import useIsClient from '../../../hooks/isClientHook';

interface Props {
    callOutTitle: string,
    callOutContent: string,
}

const HailCXNotificationFooter = ({ callOutTitle, callOutContent }: Props) => {
    const { isClient, key } = useIsClient();

    return (
        <div key={key}>
            {isClient ?
                <EuiBottomBar paddingSize="l" >
                    <EuiPageContentBody paddingSize={'none'} restrictWidth>
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup gutterSize='l' justifyContent='spaceAround'>
                                <EuiFlexItem grow={false} >
                                    <EuiCallOut title={callOutTitle} iconType='wrench'>
                                        {callOutContent}
                                    </EuiCallOut>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiPageContentBody>
                </EuiBottomBar>
                :
                <div></div>
            }
        </div>
    )
}

export default HailCXNotificationFooter;